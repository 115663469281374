/**
 * @file standalone/ugc.scss
 *
 * \brief Library file - shared styles for UGC headers
 *
 */

@import '../theme-bootstrap';

.ugc-headings {
  &__heading {
    @if $typography_cr24 == true {
      @include heading-3xl-sent;
    } @else {
      font-size: 30px;
    }
    display: flex;
    justify-content: center;
    color: $color-off-black;
    pointer-events: none;
    padding-bottom: 5px;
  }
  &__subheading {
    @if $typography_cr24 == true {
      @include body-r;
    } @else {
      font-size: 14px;
      line-height: 1.5;
    }
    display: flex;
    justify-content: center;
    color: $color-medium-gray3;
    padding-bottom: 12px;
    text-align: center;
  }
}
